@import "../global/variables";

@media #{$mediaLg} {

	/* Tabs */
	.tabs {
		&-1 {
			.tab {
				margin-right: 10px;
				font-size: 13px;
				padding: 6px 15px 9px;
			}
		}

		&-2 {
			.tab {
				padding-bottom: 16px;
				margin-right: 20px;
			}
		}
	}

	/* H1-H6 tags */
	.h1,
	h1 {
		font-size: 22px;
		line-height: 1.2;
	}

	.h2,
	h2 {
		font-size: 18px;
		line-height: 1.2;
	}

	.h3,
	h3 {
		font-size: 16px;
		line-height: 1.3;
	}

	.h4,
	h4 {
		font-size: 16px;
		line-height: 1.3;
	}

	.h5,
	h5 {
		font-size: 14px;
		line-height: 1.4;
	}

	.h6,
	h6,
	p {
		font-size: 13px;
		line-height: 1.5;
	}

	.page-title {
		&-big {
			font-size: 36px;
		}
	}
}