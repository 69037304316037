@import "./variables";

a {
	outline: 0 !important;
}

html,
body {
	height: 100%;
	overflow: hidden;
}

body {
	font: 400 14px/1.5 $font_main;
}

#root {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	min-height: 100%;

	&._hidden {
		overflow: hidden !important;
	}

	& > .container {
		flex: 1;
	}

	#page-content {
		width: calc(100% - 280px);
		padding-left: 60px;
		padding-top: 90px;

		@media #{$mediaXxl} {
			padding-left: 30px;
			padding-top: 70px;
		}

		@media #{$mediaXl} {
			padding-left: 0;
			padding-top: 50px;
			width: 100%;
		}

		@media #{$mediaMd} {
			padding-top: 30px;
		}
	}

	&._hidden {
		overflow: hidden;
		height: 100%;
	}
}

/* Position */
[class*="pos-abs"] {
	position: absolute;
}

.pos {
	position: relative;

	&-abs {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&-abs-y {
		top: 50%;
		transform: translate(0, -50%);
	}

	&-abs-x {
		left: 50%;
		transform: translate(-50%, 0);
	}
}

/* Image */
[class*="image-"] {
	width: 100%;
	height: 100%;
}

.image {
	&-cover {
		object-fit: cover;
	}

	&-contain {
		object-fit: contain;
	}
}

/* Scroll */
[class*="scroll-"] {
	-webkit-overflow-scrolling: touch;
}

.scroll {
	&-y {
		overflow: hidden auto;
		padding-right: 8px;
	}

	&-x {
		overflow: auto hidden;
	}

	&-custom {
		&::-webkit-scrollbar {
			width: 4px;
		}

		&::-webkit-scrollbar-track {
			border-radius: 10px;
			background: $color-dop-light;
		}

		&::-webkit-scrollbar-thumb {
			background: $color-blue;
			border-radius: 10px;
		}
	}
}

/* Cursor */
.cursor {
	&-pointer {
		cursor: pointer !important;
	}

	&-default {
		cursor: default !important;
	}
}

/* Z index */
.z {
	&-1 {
		z-index: 1;
	}

	&-n1 {
		z-index: -1;
	}
}

/* Background */
.bg {
	&-blue {
		background: $color-blue;
	}

	&-danger {
		background: $color-acent;
	}
}
