@import "../global/variables";

.tabs {
	display: flex;
	white-space: nowrap;

	&-1 {
		.tab {
			margin-right: 14px;
			padding: 10px 20px 13px;
			line-height: 1;
			border-radius: 5px;
			border-width: 1.5;
			border-style: solid;
			font-size: 14px;
			cursor: pointer;
			color: $color-blue;

			&:hover {
				border-color: $color-blue;
			}

			&._active {
				color: #fff;
				border-color: $color-blue;
				background: $color-blue;
			}
		}
	}

	&-2 {
		border-bottom-width: 3px;
		border-style: solid;

		.tab {
			cursor: pointer;
			font-size: 18px;
			margin-right: 34px;
			padding-bottom: 21px;

			&._active {
				position: relative;

				&::after {
					content: "";
					position: absolute;
					left: 0;
					right: 0;
					bottom: -3px;
					height: 3px;
					background: $color-blue;
				}
			}
		}
	}

	@media #{$mediaLg} {
		&-2 {
			.tab {
				font-size: 15px;
			}
		}
	}
}
