@import "../global/variables";

.go-back-btn {
	text-align: left;
	padding-left: 26px;
	margin-bottom: 40px;
	display: block;

	img {
		left: 0;
		width: 10px;
	}

	@media #{$mediaMd} {
		margin-bottom: 20px;
	}
}
