@import "../global/variables";

@media #{$mediaXl} {
	/* Form */
	.form-checkbox {
		width: 20px;
		height: 20px;
		border-radius: 6px;
		border: 1px solid fade-out(#2f2c37, 0.5);

		&:checked {
			background: url("../../../img/icon/checkbox.svg") 50% no-repeat;
			background-size: 12px auto;
		}
	}
}
