@import "../../static/css/inc/global/variables";

.page-auth {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	padding: 100px 0;

	.l-side {
		padding-right: 100px;
	}

	.r-side {
		padding-left: 100px;
	}

	&:before {
		content: "";
		width: 50%;
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		background: $color-blue;
	}

	.logo {
		width: 281px;
		margin-bottom: 86px;

		path {
			fill: $color-text-dark;
		}
	}

	.img {
		width: 100%;
		max-width: 570px;
	}

	.switchers {
		margin-bottom: 90px;

		.switcher {
			font-size: 38px;
			line-height: 1;
			letter-spacing: -0.76px;
			opacity: 0.4;

			&._active {
				opacity: 1;
			}

			&:not(:last-child) {
				margin-right: 30px;
			}
		}
	}

	form {
		.field {
			padding: 17px 70px 20px 20px;
			margin-bottom: 30px;
			cursor: pointer;
			font-size: 13px;
			color: $color-blue;
			border-radius: 8px;
			display: block;
			border-width: 1px;
			border-style: solid;
			position: relative;

			.input {
				margin-top: 20px;
				font-size: 15px;
				line-height: 1;
				width: 100%;
				font-size: 18px;

				&[type="password"] {
					letter-spacing: 10px;
				}
			}

			.icon {
				width: 24px;
				right: 28px;
			}
		}

		.btn {
			padding: 17px 15px;
		}
	}

	p {
		font-size: 16px;
		margin: 32px 0;
		text-align: center;
	}

	.agree {
		display: block;
		font-size: 13px;
		padding-left: 40px;
		margin-bottom: 40px;
		position: relative;

		.form-checkbox {
			left: 0;
		}

		a {
			text-decoration: underline;
			color: $color-blue;
		}
	}

	.soc-auth-btn {
		position: relative;
		display: block;
		width: 100%;
		padding: 15px 15px 15px 66px;
		color: $color-blue;
		font-size: 15px;

		img {
			width: 20px;
			height: 20px;
			object-fit: contain;
			left: 15px;
		}
	}

	@media #{$mediaXl} {
		.l-side {
			padding-right: 40px;
		}

		.r-side {
			padding-left: 40px;
		}

		.logo {
			width: 220px;
		}
	}

	@media #{$mediaLg} {
		padding: 30px 0;

		.l-side {
			padding-right: 15px;
		}

		.r-side {
			padding-left: 15px;
		}

		.logo {
			margin: 0 auto 30px;

			path {
				fill: $color-blue;
			}
		}

		.switchers {
			text-align: center;
			margin-bottom: 30px;
		}

		form {
			.field {
				margin-bottom: 15px;
				padding: 15px 60px 15px 15px;

				.input {
					margin-top: 12px;
				}
			}

			img {
				right: 15px;
			}
		}

		&:before {
			display: none;
		}
	}

	@media #{$mediaMd} {
		.logo {
			width: 200px;
		}

		.switchers {
			.switcher {
				font-size: 28px;

				&:not(:last-child) {
					margin-right: 20px;
				}
			}
		}

		form {
			.field {
				padding-top: 12px;
				padding-right: 44px;

				.input {
					font-size: 16px;

					&[type="password"] {
						letter-spacing: 6px;
					}
				}

				.icon {
					width: 20px;
					right: 10px;
				}
			}

			.btn {
				padding: 15px;
			}
		}

		p {
			margin: 30px 0 10px;
			font-size: 14px;
		}

		.soc-auth-btn {
			font-size: 14px;
			padding: 12px 13px 14px 44px;

			img {
				left: 13px;
				width: 18px;
				height: 18px;
			}
		}
	}

	@media #{$mediaSm} {
		.switchers {
			.switcher {
				font-size: 24px;
			}
		}

		form {
			.field {
				.input {
					font-size: 14px;
				}
			}
		}
	}
}
