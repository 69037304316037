@import "../global/variables";

body._light {
	background: $color-bg-light;

	/* Super common */
	.c {
		&-text {
			color: $color-text-light;

			&-fade {
				color: fade-out($color-text-light, 0.5);
			}
		}

		&-blue {
			color: $color-blue;
		}
	}

	.bg {
		&-dop {
			background: $color-dop-light;
		}
		&-bg {
			background: $color-bg-light;
		}
	}

	.border {
		&-1 {
			border: 1px solid $color-border-light;
		}

		&-radius {
			border-radius: 8px;
		}
	}

	/* UI */
	.btn {
		&-bg {
			background: $color-dop-light;
			color: $color-blue;

			&:hover {
				background: #eaedef;
			}
		}

		&-dark {
			background: $color-bg-light;
			color: $color-text-light;

			&:hover {
				background: #2a2c33;
			}
		}
	}

	.form {
		.form-input-wrap {
			.label {
				color: fade-out($color-text-light, 0.5);
			}

			.input {
				color: $color-text-light;
				border-color: $color-border-light;
				background-color: $color-dop-light;
				@include placeholder(fade-out($color-text-light, 0.5));

				&._sex-select {
					border-color: $color-dop-light;
				}

				&._select {
					background-image: url("../../../img/icon/select-arrow-dark.svg");
				}
			}

			.calendar-open {
				path {
					fill: #000;
				}
			}
		}

		.form-checkbox {
			border: 1px solid fade-out(#2f2c37, 0.5);
		}
	}

	.tabs {
		&-1 {
			.tab {
				border-color: #efeeee;
			}
		}

		&-2 {
			border-color: #f1f0f0;

			.tab {
				color: $color-text-light;

				&._active {
					color: $color-blue;
				}
			}
		}
	}

	/* Text */

	.page-title {
		&._fade {
			color: fade-out($color-text-light, 0.5);
		}

		&._back {
			color: inherit;
		}
	}

	/* Header */
	#page-header {
		box-shadow: 6.4px 7.7px 47.5px 0 rgba(20, 44, 78, 0.06);

		.logo {
			path {
				fill: $color-blue;
			}
		}

		.form {
			border-color: #efeeee;

			.loop-btn {
				path {
					fill: $color-blue;
				}
			}

			.close-btn {
				path {
					fill: $color-dop-dark;
				}
			}
		}

		.user {
			background: $color-dop-light;
		}

		.exit {
			path {
				fill: fade-out($color-text-light, 0.7);
			}
		}
	}

	.smart-search {
		.item {
			border-bottom: 2px solid $color-dop-light;
		}
	}

	/* Left menu */
	#left-menu {
		&:before {
			background: $color-dop-light;
		}

		.link {
			color: fade-out($color-text-light, 0.6);

			&._active {
				color: $color-text-light;
			}
		}
	}

	/* Footer */
	#page-footer {
		.links {
			.link {
				&._phone {
					path {
						fill: fade-out(#000, 0.6);
					}
				}
			}
		}

		.social-links {
			.soc-link {
				path {
					fill: fade-out(#000, 0.4);
				}
			}
		}

		.copyright {
			color: fade-out($color-text-light, 0.6);
		}
	}

	/* Pages */
	.page-auth {
		.switchers {
			.switcher {
				color: $color-text-light;
			}
		}

		form {
			.input {
				color: $color-text-light;
				@include placeholder(fade-out($color-text-light, 0.5));
			}
		}

		p {
			color: fade-out($color-text-light, 0.5);
		}

		.agree {
			color: fade-out($color-text-light, 0.2);
		}
	}

	.page-lesson {
		.tabs {
			.tab {
				path {
					fill: $color-text-light;
				}
			}

			&::after {
				background: #e8e8e8;
			}
		}

		.lesson-versions {
			.version-list {
				select {
					background-color: $color-dop-light;
					color: $color-text-light;
					border-color: $color-border-light;
				}
			}

			.btn {
				&._back {
					path {
						fill: $color-bg-dark;
					}
				}
			}
		}

		.answer-list {
			.answer {
				color: $color-text-light;
			}
		}

		.question-wrap {
			border-top-color: fade-out(#e2e2e2, 0.3);

			.answer {
				background: $color-dop-light;

				.arrow {
					path {
						fill: $color-text-light;
					}
				}
			}
		}

		.msg-form {
			.input {
				border-color: #efeeee;
				color: $color-text-light;
			}
		}

		.footer-btns {
			border-top-color: fade-out(#e2e2e2, 0.3);
		}

		.lesson-rating {
			.rate-wrap {
				.star {
					border-color: $color-dop-light;
				}
			}
		}

		.attach-files {
			.file {
				color: $color-text-light;
				background: $color-dop-light;
			}
		}
	}

	.page-chat {
		.chat-wrap {
			.msg-list {
				.msg-wrap {
					.text-wrap {
						color: #302549;
					}
				}
			}

			.attach-files {
				.file {
					color: $color-text-light;
					background: $color-dop-light;
				}
			}
		}

		.contacts-wrap {
			.contact-list {
				.contact {
					color: $color-text-light;
					background: $color-dop-light;

					&._unread {
						background: #e3e2e2;
					}
				}
			}
		}
	}

	.page-profile {
		.line {
			background: #eee7e7;
		}
	}

	/* Components */
	.course-item {
		.content {
			.course-name {
				&._gray {
					color: rgba(29, 40, 56, 0.4);
				}
			}

			.thumb-data-wrap {
				.thumb-item {
					&._gray {
						color: fade-out($color-text-light, 0.4);
					}
				}
			}
		}
	}

	.pagination {
		a {
			color: fade-out($color-text-light, 0.6);
		}
	}

	.cmp-faq-category {
		.name {
			color: $color-text-light;
		}
	}

	.cmp-accordeon {
		.header {
			.name {
				color: $color-blue;
			}

			.arrow {
				path {
					fill: $color-blue;
				}
			}
		}

		&._opened {
			.header {
				.icon {
					path {
						fill: $color-text-light;
					}
				}

				.name {
					color: $color-text-light;
				}
			}
		}
	}

	.cmp-direction {
		.name {
			color: $color-blue;
		}

		.des {
			color: fade-out($color-text-light, 0.4);
		}

		.price {
			color: $color-text-light;
		}

		&:hover {
			border-color: $color-border-light;
		}
	}

	.cmp-course {
		.name {
			color: $color-text-light;
		}

		.thumb {
			color: $color-blue;

			path {
				fill: $color-blue;
			}
		}

		.bottom {
			.price {
				color: $color-text-light;
			}
		}
	}

	.cmp-module {
		.content {
			.lesson {
				background: $color-dop-light;
				color: $color-text-light;

				.video-icon {
					path {
						fill: $color-text-light;
					}
				}

				.data {
					color: fade-out($color-text-light, 0.5);
				}

				.play-btn {
					path {
						fill: $color-text-light;
					}
				}

				&._active {
					color: #fff;
				}
			}
		}

		&._passed {
			.header {
				.info {
					color: fade-out($color-text-light, 0.2);
				}
			}
		}
	}

	.msg-wrap {
		.text-wrap {
			background: $color-dop-light;
			color: #302549;

			path {
				fill: $color-text-light;
			}
		}

		.time {
			color: #9ea4a9;
		}

		&._me {
			.text-wrap {
				path {
					fill: $color-text-dark;
				}
			}
		}
	}

	.go-back-btn {
		color: fade-out($color-text-light, 0.4);
	}

	.cmp-my-course {
		color: $color-text-light;

		.course-count {
			color: $color-blue;

			path {
				fill: $color-blue;
			}
		}

		.pass-icon {
			.circle {
				&._c1 {
					background: $color-bg-dark;
				}

				&._c2 {
					background: $color-bg-light;
				}

				&._c3 {
					background: $color-bg-dark;
				}
			}
		}
	}

	.cmp-text-test {
		.answer {
			border-color: $color-border-light;
			color: fade-out($color-text-light, 0.4);

			&._active {
				color: $color-text-light;
				background: $color-dop-light;
				border-color: $color-dop-light;
			}
		}
	}

	.cmp-test-wrap {
		color: #fff;
	}

	/* Modal */
	.modal {
		.wrapper {
			border-color: $color-dop-light;

			form {
				.send-btn {
					path {
						fill: $color-text-light;
					}
				}
			}
		}
	}

	/* Adaptive */
	@media #{$mediaLg} {
		.page-chat {
			.contacts-wrap {
				.contact-list {
					.contact {
						background: $color-bg-light;
					}
				}
			}
		}
	}
}
