@font-face {
	font-family: "StyreneAWeb";
	src: url("../../../../static/font/StyreneAWeb-Black.woff2") format("woff2"),
		url("../../../../static/font/StyreneAWeb-Black.woff") format("woff");
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "StyreneAWeb";
	src: url("../../../../static/font/StyreneAWeb-Bold.woff2") format("woff2"),
		url("../../../../static/font/StyreneAWeb-Bold.woff") format("woff");
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "StyreneAWeb";
	src: url("../../../../static/font/StyreneAWeb-Regular.woff2") format("woff2"),
		url("../../../../static/font/StyreneAWeb-Regular.woff") format("woff");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}
