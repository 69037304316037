@import "../global/variables";

button {
	font-family: inherit;
}

.btn {
	text-decoration: none !important;
	cursor: pointer;
	font-weight: 400 !important;
	font-size: 16px !important;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 15px 32px 17px;
	border-radius: 5px;
	line-height: 1;
	transition: all 0.3s;

	&-primary {
		background: $color-blue;
		color: #fff;

		&:hover {
			background: #1861cc;
		}
	}

	&-danger {
		background: $color-acent;
		color: #fff;

		&:hover {
			background: #fe6250;
		}
	}

	&._shadow {
		box-shadow: 12.9px 15.3px 25px 0 rgba(20, 44, 78, 0.22);
	}

	&-block {
		width: 100%;
	}

	&-wide {
		padding: 16px 60px;
	}

	&-xl {
		padding: 22px 38px;
	}

	&-sm {
		font-size: 14px !important;
		padding: 11px 25px 12px;
	}

	&-xs {
		font-size: 12px !important;
		padding: 5px 10px;
	}
}
