@import "../../static/css/inc/global/variables";

#left-menu {
	padding: 100px 15px 50px 0;
	width: 280px;
	z-index: 1;
	position: relative;

	&:before {
		content: "";
		position: absolute;
		left: -100%;
		top: 0;
		bottom: 0;
		right: 0;
		z-index: -1;
	}

	.link {
		font-size: 16px;
		position: relative;
		display: block;
		padding-left: 52px;
		margin-bottom: 50px;
		line-height: 1;

		.icon {
			left: 0;
			width: 34px;
			height: 34px;
			border-radius: 5px;

			svg {
				width: 20px;
				height: 20px;

				path {
					fill: $color-blue;
				}
			}
		}

		.badge {
			width: 20px;
			height: 20px;
			border-radius: 100%;
			color: #fff;
			line-height: 20px;
			text-align: center;
			background: $color-acent;
		}

		&._active {
			.icon {
				box-shadow: 4.5px 5.4px 15px 0 rgba(20, 44, 78, 0.22);
				background: $color-blue;

				svg {
					path {
						fill: #fff;
					}
				}
			}
		}
	}

	.download-link {
		background: $color-blue;
		width: 190px;
		height: 60px;
		position: relative;
		display: flex;
		align-items: center;
		font-size: 14px;
		line-height: 1.3;
		color: #fff;
		border-radius: 5px;
		margin-top: 20px;
		padding-left: 63px;
		box-shadow: 12.9px 15.3px 25px 0 rgba(20, 44, 78, 0.22);

		.icon {
			left: 19px;
			width: 26px;
			height: 26px;
			object-fit: contain;
		}
	}

	&._visible {
		left: 0;
	}

	@media #{$mediaXxl} {
		padding: 80px 15px 40px 0;
	}

	@media #{$mediaXl} {
		padding: 50px 20px 20px;
		position: fixed;
		top: 74px;
		left: 0;
		bottom: 0;
		z-index: 101;
		transition: all 0.3s;
		left: -280px;
		overflow-y: auto;
		overflow-x: hidden;
		overflow-scrolling: touch;

		&:before {
			display: none;
		}

		.download-link {
			width: 100%;
		}
	}

	@media #{$mediaLg} {
		.link {
			margin-bottom: 40px;
		}
	}

	@media #{$mediaMd} {
		top: 58px;
	}
}
