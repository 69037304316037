@import "../global/variables";

@media #{$mediaSm} {
	.tabs {
		&-1 {
			.tab {
				font-size: 13px;
				padding: 7px 15px 10px;
			}
		}

		&-2 {
			.tab {
				margin-right: 20px;
				padding-bottom: 14px;
			}
		}
	}
}