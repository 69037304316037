@import "../../static/css/inc/global/variables";

.rightSide {
	width: calc(100% - 280px);
	padding-left: 60px;
	padding-top: 90px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

@media #{$mediaXl} {
	.rightSide {
		width: 100%;
		padding-left: 0;
	}
}