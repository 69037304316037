/* H1-H6 tags */
.h1,
h1 {
	font-size: 28px;
	line-height: 1.2;
}

.h2,
h2 {
	font-size: 22px;
	line-height: 1.2;
}

.h3,
h3 {
	font-size: 20px;
	line-height: 1.3;
}

.h4,
h4 {
	font-size: 18px;
	line-height: 1.3;
}

.h5,
h5 {
	font-size: 16px;
	line-height: 1.4;
}

.h6,
h6,
p {
	font-size: 14px;
	line-height: 1.5;
}

.h7 {
	font-size: 12px;
	line-height: 1.3;
}

.pre {
	white-space: pre-wrap;
}

.page-title {
	@extend .h1;
	display: block;
	margin-bottom: 50px;

	&-big {
		font-size: 43px;
		letter-spacing: -0.86px;
	}

	&._back {
		padding-left: 35px;
		position: relative;

		img {
			width: 13px;
			left: 0;
		}
	}
}

.direction-des {
	white-space: pre-line;
	opacity: 0.7;
	border-radius: 6px;
}

b,
strong {
	font-weight: 700;
}

.f {
	/* Text case */
	&-upper {
		text-transform: uppercase;
	}

	&-lower {
		text-transform: lowercase;
	}

	/* Font weight */
	&-900 {
		font-weight: 900;
	}

	&-700 {
		font-weight: 700;
	}

	&-400 {
		font-weight: 400;
	}
}

/* Text align */
.ta {
	&-left {
		text-align: left;
	}

	&-center {
		text-align: center;
	}

	&-right {
		text-align: right;
	}
}

/* Text color */
.c {
	&-white {
		color: #fff;
	}
}

.error-text {
	color: $color-acent;
}
