@import "../global/variables";

.pagination {
	text-align: center;
	display: flex;

	a {
		width: 50px;
		height: 82px;
		line-height: 82px;
		border-radius: 8px;
		font-weight: 800;
		border: solid 1px transparent;

		&:hover {
			color: $color-blue;
		}

		&._active {
			border-color: $color-blue;
			color: $color-blue;
		}
	}
}