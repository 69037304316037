@import "../global/variables";

#page-header {
	padding: 20px 0;
	z-index: 100;
	line-height: 1;

	.left-side {
		position: relative;
		width: 280px;
	}

	.right-side {
		width: calc(100% - 280px);
		padding-left: 60px;
	}

	.mob-menu-toggle {
		left: 0;
		width: 44px;
		height: 44px;
		border-radius: 5px;

		span {
			width: 20px;
			height: 2px;
			background: #1952a5;

			&:before,
			&:after {
				content: "";
				position: absolute;
				height: 2px;
				width: 100%;
				left: 0;
				background: #1952a5;
				transition: all 0.1s;
			}

			&:before {
				top: -6px;
			}

			&:after {
				bottom: -6px;
			}
		}

		&._active {
			span {
				background: transparent !important;

				&:before,
				&:after {
					top: 0;
				}

				&:before {
					transform: rotate(45deg);
				}

				&:after {
					transform: rotate(-45deg);
				}
			}
		}
	}

	.logo {
		display: block;
		width: 100%;
		max-width: 220px;

		svg {
			height: 30px;
			width: 100%;
		}
	}

	.form {
		z-index: 1;
		position: relative;
		width: 74%;
		border-width: 1px;
		border-style: solid;
		border-radius: 5px;

		.button {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			width: 58px;

			img {
				width: 20px;
				height: 20px;
				object-fit: contain;
			}
		}

		.form-input {
			width: 100%;
			padding: 16px 20px 15px 58px;
			font-size: 16px;
			border-radius: 5px;
			@include placeholder(fade-out($color-text-dark, 0.5));
		}
	}

	.open-form-btn {
		width: 36px;
		height: 36px;
		border-radius: 5px;
		margin-right: 15px;

		path {
			fill: $color-blue;
		}
	}

	.user {
		position: relative;
		border-radius: 5px;
		padding: 20px 15px 20px 64px;

		.avatar {
			left: 15px;
			width: 34px;
			height: 34px;
			border-radius: 50%;
		}

		.name {
			color: $color-blue;
			width: 100px;
			@include text-overflow(1, 18px);
		}
	}

	@media #{$mediaXxl} {
		font-size: 14px;
		padding: 15px 0;

		.right-side {
			padding-left: 30px;
		}

		.logo {
			max-width: 200px;
		}

		.form {
			width: 65%;

			.form-input {
				padding: 10.5px 20px 12.5px 50px;
			}

			.button {
				width: 50px;
			}
		}

		.user {
			padding: 16px 15px 16px 55px;

			.avatar {
				left: 8px;
			}
		}

		.exit {
			height: 46px;
		}
	}

	@media #{$mediaXl} {
		.left-side {
			width: 240px;
		}

		.right-side {
			width: calc(100% - 240px);
		}

		.logo {
			margin-left: 50px;
			width: 150px;
		}

		.form {
			width: 58%;

			.button {
				width: 44px;
			}

			.form-input {
				font-size: 14px;
				padding: 12px 15px 14px 44px;
			}
		}

		.user {
			padding: 15px 15px 17px 50px;

			.avatar {
				left: 10px;
				width: 26px;
				height: 26px;
			}
		}

		.exit {
			height: 44px;
		}
	}

	@media #{$mediaLg} {
		.left-side {
			width: 220px;
		}

		.right-side {
			padding-left: 15px;
			width: calc(100% - 220px);
		}

		.mob-menu-toggle {
			width: 36px;
			height: 36px;
		}

		.form {
			width: calc(100% - 100px);

			.button {
				svg {
					width: 16px;
					height: 16px;
				}
			}

			.form-input {
				padding: 10px 15px 12px 44px;
			}
		}

		.user {
			padding: 0;
			background: transparent;
			width: 40px;
			height: 40px;
			overflow: hidden;

			.avatar {
				border-radius: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}

	@media #{$mediaMd} {
		padding: 10px 0;

		.right-side {
			width: auto;
		}

		.logo {
			width: 140px;
		}

		.form {
			border-top: none;
			border-left: none;
			border-right: none;
			border-radius: 0;
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 58px;
			background: #fff;
			z-index: 10;
			transform: translate(0, -100%);
			transition: all 0.3s;
			box-shadow: none;

			.button {
				width: 60px;

				img {
					width: 22px;
					height: 22px;
				}
			}

			.form-input {
				padding: 21.5px 60px;
				font-size: 16px;
				border-radius: 0;
			}

			.close-btn {
				width: 60px;
				height: 100%;
				right: 0;
				opacity: 0.4;
			}

			&.is-open {
				transform: translate(0, 0);
			}
		}

		.user {
			width: 36px;
			height: 36px;
		}
	}

	@media #{$mediaSm} {
		.open-form-btn {
			margin-right: 5px;
		}
	}

	@media #{$media320} {
		padding: 11px 0;

		.logo {
			width: 130px;
		}

		.user {
			display: none;
		}
	}
}
