@import "../global/variables";

#page-footer {
	padding: 150px 0 40px 0;

	.links {
		margin-bottom: 55px;

		.link {
			color: inherit;
			margin-right: 40px;

			&._phone {
				padding-left: 30px;

				svg {
					left: 0;
					width: 20px;
					height: 20px;
				}
			}
		}
	}

	.social-links {
		.soc-link {
			display: flex;
			margin-left: 35px;
			width: 22px;
			height: 22px;

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}

			&:first-child {
				margin-left: 0 !important;
			}
		}
	}

	.copyright {
		margin-left: 50px;
		font-size: 13px;
		letter-spacing: -0.26px;

		&-link {
			text-decoration: underline;
		}
	}

	@media #{$mediaXxl} {
		padding: 100px 0 30px 0;

		.copyright {
			margin-left: 30px;
		}
	}

	@media #{$mediaLg} {
		.links {
			margin-bottom: 30px;

			.link {
				margin: 10px 20px 0 0;

				&:last-child {
					margin-right: 0 !important;
				}
			}
		}

		.social-links {
			.soc-link {
				margin-left: 20px;
			}
		}
	}

	@media #{$mediaMd} {
		padding: 50px 0 30px 0;

		.copyright {
			margin: 10px 15px 0 0;
		}
	}

	@media #{$media475} {
		.copyright {
			display: block;
			margin: 3px 0 0;
		}
	}
}
