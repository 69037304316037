@import "../../static/css/inc/global/variables";

.smart-search {
	position: absolute;
	top: -10px;
	left: -10px;
	right: -10px;
	padding: 80px 15px 15px;
	border-radius: 10px;
	box-shadow: 6.4px 7.7px 58.5px 0 rgba(20, 44, 78, 0.1);
	z-index: -1;

	.list {
		max-height: 300px;
	}

	.item {
		display: block;
		padding: 20px 0 20px 35px;
		position: relative;
		color: $color-blue;

		svg {
			width: 20px;
			height: 20px;
			left: 0;

			path {
				fill: $color-blue;
			}
		}

		&:last-child {
			border-bottom: none;
		}
	}

	@media #{$mediaMd} {
		.item {
			font-size: 14px;
			padding: 16px 0 16px 35px;

			svg {
				width: 18px;
				height: 18px;
			}
		}
	}
}