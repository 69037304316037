@import "../global/variables";

@media #{$mediaMd} {

	/* Button */
	.btn {
		font-size: 14px !important;
	}

	.page-title {
		margin-bottom: 30px;
	}
}