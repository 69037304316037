@import "../global/variables";

.form-field {
	margin-bottom: 30px;
}

input,
select,
textarea {
	font-family: inherit;
}

.form-input-wrap {
	display: block;
	font-size: 13px;

	.label {
		display: block;
		padding: 0 0 10px 20px;
	}

	.input {
		width: 100%;
		font-size: 16px;
		line-height: 1;
		border-radius: 5px;
		border-style: solid;
		border-width: 1px;
		padding: 10.5px 20px 12.5px;

		&._error {
			border-color: rgba(240, 60, 60, 0.4);
		}

		&[type="date"] {
			height: 46px;
		}

		&[type="password"] {
			letter-spacing: 8px;
		}

		&._sex-select {
			padding: 14px 20px;

			label {
				display: block;
				cursor: pointer;
				padding-left: 31px;

				input {
					left: 0;
					width: 16px;
					height: 16px;
					border-radius: 5px;
					box-shadow: 0 0 0 1px fade-out($color-text-light, 0.5);

					&:checked {
						background: $color-blue;
						box-shadow: 0 0 0 1px $color-blue, inset 0 0 0 2px #fff;
					}
				}
			}
		}

		&._select {
			padding-right: 36px;
			padding-top: 13px;
			padding-bottom: 15px;
			background-position: calc(100% - 10px) 50%;
			background-repeat: no-repeat;
			background-size: 12px auto;
		}
	}

	.error {
		margin-top: 5px;
		color: $color-acent;
	}
}

.form-checkbox {
	width: 24px;
	height: 24px;
	border-radius: 6px;
	cursor: pointer;

	&:checked {
		background: url("../../../img/icon/checkbox.svg") 50% 46% no-repeat;
		background-size: 12px auto;
	}
}

@media #{$mediaXxl} {
	.form-field {
		margin-bottom: 20px;
	}

	.form-input-wrap {
		.label {
			padding: 0 0 10px 15px;
		}

		.input {
			padding: 10.5px 15px 12.5px;

			&[type="date"] {
				height: 46px;
			}

			&._sex-select {
				font-size: 14px;
				padding: 15px;

				label {
					padding-left: 25px;
				}
			}
		}
	}
}
